import {graphql, useStaticQuery} from "gatsby";
import React from 'react'

import Layout from '/src/components/layout/en/Layout'
import HeroSmall from '/src/components/hero-small/HeroSmall.js'
import Seo from "/src/components/seo/index";

import Contact from '/src/components/contact/Contact'
import Location from '/src/components/location/Location'
import EnquirySection from "/src/components/enquiry-section/EnquirySection";

const ContactUs = (props) => {
  
  const {strapiContactUs, allStrapiContacts, strapiEnquiry} = useStaticQuery(query);
  return (
        <Layout path={props.location.pathname} black={true}>
          <Seo
            title={strapiContactUs.metaTitle}
            description={strapiContactUs.metaDescription}
            keywords={strapiContactUs.metaKeywords} />
            <HeroSmall pathname={props.location.pathname} img={null} strapiHomePage={strapiContactUs} black={true}/>
            <Contact contact={allStrapiContacts}/>
            <Location />
            <EnquirySection datas={strapiEnquiry} />
        </Layout>
    )
}

export default ContactUs

const query = graphql`
    query {
        strapiContactUs(locale: {eq: "en"}) {
            metaTitle
            metaDescription
            metaKeywords
            title
            subtitle
            breadcrumb {
                label
                url
            }
        }
        allStrapiContacts {
            edges {
                node {
                    City
                    Address
                    AddressDetails
                    Emirate
                    POBox
                    PhoneNumber1
                    PhoneNumber2
                    Email
                }
            }
        }
        strapiEnquiry {
            name
            email
            phone
            company
            message
            logo {
                url
            }
        }
    }
`;
