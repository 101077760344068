import React, {useState} from 'react'
import styled from 'styled-components'
import {navigate} from 'gatsby'
import {Formik, Form} from "formik"
import * as Yup from "yup"
import Input from '/src/components/library/Input'
import TextArea from "/src/components/library/TextArea";
import {contactUs} from "../../services/contact-us.js";

import H2 from '/src/components/library/H2'
import Button from '/src/components/library/Button'

import logoImg from '/static/images/contact-us/enquiry/logo.png'

const EnquirySection = ({isModal = false, datas}) => {
    const [result, setResult] = useState(null)

    const submitForm = async (values) => {
        try {
            const val = {
                name: values.fullName,
                topic: values.subject,
                email: values.email,
                message: values.yourMessage,
                companyName: values.companyName,
                phoneNumber: values.phoneNumber
            }
            await contactUs(val);
            setResult("success");
            navigate('/thank-you')
        } catch (e) {
            // console.log("E", e);
            // setResult("error");

            // if (e.code === 6)
            //     setError(dataCockpit.cockpitFooter.alreadySigned.value);
            // else
            //     setError("Error, please try again later");
        }
    }

    return (
        <ContactStyle isModal={isModal}>
            <Wrapper>
            <FormikWrapper>
                <Formik
                    enableReinitialize
                    initialValues={{
                        subject: 'INFO',
                        fullName: '',
                        email: '',
                        phoneNumber: '',
                        companyName: '',
                        yourMessage: ''
                    }}
                    validationSchema={Yup.object({
                        fullName: Yup.string()
                            .matches(/^[a-zA-Z\s]*$/, 'Please enter a valid full name')
                            .required("Please enter a full name"),
                        phoneNumber: Yup.string()
                            .matches(/^[+]?\d+$/, 'Please enter a valid phone number')
                            .required('Please enter a phone number'),
                        companyName: Yup.string()
                            .required("Please enter a company name"),
                        yourMessage: Yup.string()
                            .required("Please enter a message"),
                        subject: Yup.string()
                            .required("Field required"),
                        email: Yup.string()
                            .email("Must be an email")
                            .required("Please enter a valid email"),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        submitForm(values);
                    }}
                >

                    {props => (
                        <Form>
                            <FormWrapper isModal={isModal}>
                                <TitleWrapper>
                                    <H2>Make an Enquiry</H2>
                                </TitleWrapper>
                                <Input name={'fullName'} id={'fullName'} placeholder={datas.name} type={'text'}/>
                                <Input name={'email'} id={'email'} placeholder={datas.email} type={'email'}
                                       className={'email'}/>
                                <Input name={'phoneNumber'} id={'phoneNumber'} placeholder={datas.phone}
                                       type={'text'}
                                       className={'phoneNumber'}/>
                                <Input name={'companyName'} id={'companyName'} placeholder={datas.company}
                                       type={'text'}
                                       className={'company'}/>
                                {/*<Input name={'yourMessage'} id={'yourMessage'} placeholder={'Your message'} type={'text'}*/}
                                {/*       className={'message'}/>*/}
                                <TextArea placeholder={datas.message} name={'yourMessage'} id={'yourMessage'}
                                          rows={'6'}/>
                                <ButtonWrapper>
                                    {result === "success" &&
                                        <Success><p>Thank you, message sent !</p></Success>
                                    }
                                    {result === "error" &&
                                        <Success><p>Good news, your email is already registered!</p></Success>
                                    }
                                    {/*<div></div>*/}
                                    {/*<ButtonStyled primary type={'submit'} label={'Submit'}/>*/}
                                    <Button label={'Submit'}/>
                                </ButtonWrapper>
                            </FormWrapper>
                        </Form>
                    )}
                </Formik>
            </FormikWrapper>
            <LogoWrapper>
                    <img alt={'logo'} width={'266px'} height={'370px'} src={`${process.env.GATSBY_STRAPI_API_URL}${datas.logo.url}`}/>
            </LogoWrapper>
            </Wrapper>
        </ContactStyle>
    )
}

export default EnquirySection

const Wrapper = styled.div`
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  padding: 6rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding: 0;
    //padding: 6rem 1.4rem;
    flex-direction: column;
    gap: 1.4rem;
  }
  // @media ${({theme}) => theme.breakpoints.mobile} {
  //   padding: 2.6rem 2.4rem;
  // }
`

const LogoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100%;
  gap: ${({isModal}) => isModal ? '55px' : 'none'};
  align-items: center;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    display: none;
  }
`

const ContactStyle = styled.div`
  // padding-top: 10rem;
  // padding-left: 12rem;
  // padding-right: 12rem;
  // padding-bottom: 9.2rem;
    // padding-top: ${({isModal}) => isModal ? '5.6rem' : '9.2rem'};
    // padding-bottom: ${({isModal}) => isModal ? '5.6rem' : '9.2rem'};
    // padding-right: ${({isModal}) => isModal ? '9.4rem' : '12rem'};
    // padding-left: ${({isModal}) => isModal ? '6rem' : '12rem'};

  display: flex;
  justify-content: center;
  
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-bottom: 3.5rem;

    padding-top: ${({isModal}) => isModal ? '40px' : '33px'};
    padding-bottom: ${({isModal}) => isModal ? '40px' : '100px'};
    padding-right: ${({isModal}) => isModal ? '14px' : '14px'};
    padding-left: ${({isModal}) => isModal ? '14px' : '14px'};

    gap: ${({isModal}) => isModal ? '30px' : 'none'};

    flex-direction: column;
    justify-content: center;
    align-items: center;
    form {
      width: 100%;
    }
    button {
      width: fit-content;
    }
  }
`

const Success = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
  padding: 1rem 0rem;
  color: #53976F;
`

const FormikWrapper = styled.div`
  flex: 1;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({isModal}) => isModal ? 'auto' : 'auto'};

  #select-subject {
    //-webkit-appearance: none;
    //-webkit-appearance: menulist;
    //-moz-appearance: none;
    //appearance: none;

    //-webkit-border-radius: 3px;

    padding-top: 1.4rem;
    padding-bottom: 2rem;
    border: none;
    outline: none;
    font-family: Sequel45;
    font-size: 1.6rem;
    line-height: 2.8rem;
    color: #959595;
    //-webkit-border: none;
    //-webkit-border-bottom: .1rem solid #B8B8B8;
    //-webkit-box-shadow: none;
    //-moz-box-shadow: none;
    //-webkit-text-decoration-style: none;
    //box-shadow: none;
    border-bottom: .1rem solid #B8B8B8;
  }


  @media ${({theme}) => theme.breakpoints.mobile} {
    //margin-top: 7.5rem;
    #select-subject {
      padding-top: 0;
    }

    margin-top: ${({isModal}) => isModal ? '3rem' : '0'};
    width: 100% !important;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 2rem;
`

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  // @media ${({theme}) => theme.breakpoints.mobile} {
  //   flex-direction: column;
  // }
`
