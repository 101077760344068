import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import downArrowSvg from '/static/images/contact-us/location/Dropdown.svg'


const Location = () => {
    const [loc, setLoc] = useState(false)
    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);


    useEffect(() => {
        document.getElementById('select-dropdown').onchange = function () {
            if (this.value === '0')
                setLoc(false)
            else
                setLoc(true)
            // forceUpdate()
        }
    },)

    return (
        <StyledLocation>
            <Map>
                {!loc &&
                    <IframeWrapper>
                        <iframe title={"map"} width="100%" height="700px" frameBorder="0" scrolling="no" marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=700&amp;hl=en&amp;q=Sons%20of%20Jassim%20Darwish%20Building%20Khalidiya+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        </iframe>
                        <Redirect
                            href={'https://maps.google.com/maps?ll=25.201496,55.274463&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=1354994934497181572'}
                            target={'_blank'}>

                        </Redirect>
                    </IframeWrapper>
                }
                {loc &&
                    <IframeWrapper>
                        <iframe title={"map"} id={'location-frame'} width="100%" height="700px" frameBorder="0" scrolling="no"
                                marginHeight="0" marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Emaar%20square%20building%206+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        </iframe>
                        <Redirect
                            href={'https://maps.google.com/maps?ll=25.201496,55.274463&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=1354994934497181572'}
                            target={'_blank'} rel={'noreferrer'}></Redirect>
                    </IframeWrapper>
                }
                <select style={{fontSize: '16px'}} id={'select-dropdown'}>
                    {/*<option disabled selected>Abu Dhabi</option>*/}
                    <option value={'0'}>Abu Dhabi</option>
                    <option value={'1'}>Dubai</option>
                </select>
            </Map>
        </StyledLocation>
    )
}

export default Location

const StyledLocation = styled.div`
  display: flex;
  justify-content: center;

  #select-dropdown {
    width: 286px;
    height: 66px;
    position: absolute;
    right: 4rem;
    top: 4rem;
    padding: 0 2.4rem;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #dddddd;
    cursor: pointer;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url(${downArrowSvg});
    background-size: 24px;
    background-position: right 20px top 21px;
    background-repeat: no-repeat;

    @media ${({theme}) => theme.breakpoints.tablets_reverse} {
      right: 50%;
      top: unset;
      bottom: 4rem;
      width: 24rem;
      transform: translateX(50%);
    }
  }

  #select-dropdown:focus,
  #select-dropdown:hover {
    outline: none;
    border: 1px solid #bbbbbb;
  }

  #select-dropdown option {
    background: #ffffff;
    font-size: 1.6rem;
  }
`

const Map = styled.div`
  position: relative;
  width: 100%;
  height: 700px;

  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  padding: 0 2.4rem;
  
  select {
    border-radius: 12px;
  }
  
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    padding: 0;
  }
`

const IframeWrapper = styled.div`
  width: 100%;
  z-index: 0;
  position: relative;
  iframe {
    border-radius: 12px;
  }
`

const Redirect = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 700px;
  z-index: 5;
`
