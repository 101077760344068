import React, {useEffect} from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import Body from '/src/components/library/Body'

const Contact = ({ contact }) => {

    useEffect(() => {
    })

    return (
        <StyledContact>
            <Wrapper>
                <TextWrapper>
                    <TitleWrapper>
                        <H2>{contact.edges[0].node.City}</H2>
                    </TitleWrapper>
    
                    <BodyWrapper>
                        <Body>{contact.edges[0].node.Address}</Body>
                        <Body>{contact.edges[0].node.AddressDetails}</Body>
                        <Body>{contact.edges[0].node.Emirate}</Body>
                        <Body>{contact.edges[0].node.POBox}</Body>
                        <Body><a href={'tel:+971-2-635-0229'}>{contact.edges[0].node.PhoneNumber1}</a></Body>
                        <Body><a href={'tel:+971-2-633-0403'}>{contact.edges[0].node.PhoneNumber2}</a></Body>
                    </BodyWrapper>
    
                    <MailWrapper href={`mailto:${contact.edges[0].node.Email}`}>
                        <Body>{contact.edges[0].node.Email}</Body>
                    </MailWrapper>
                </TextWrapper>
                <TextWrapper>
                    <TitleWrapper>
                        <H2>{contact.edges[1].node.City}</H2>
                    </TitleWrapper>
                    <BodyWrapper>
                        <Body>{contact.edges[1].node.Address}</Body>
                        <Body>{contact.edges[1].node.AddressDetails}</Body>
                        <Body>{contact.edges[1].node.Emirate}</Body>
                        <Body>{contact.edges[1].node.POBox}</Body>
                        <Body><a href={'tel:+971-4-388-8087'}>{contact.edges[1].node.PhoneNumber1}</a></Body>
                    </BodyWrapper>
    
                    <MailWrapper href={`mailto:${contact.edges[1].node.Email}`}>
                        <Body>{contact.edges[1].node.Email}</Body>
                    </MailWrapper>
                </TextWrapper>
            </Wrapper>
        </StyledContact>
    )
}

export default Contact

const Wrapper = styled.div`

    max-width: ${({ theme }) => theme.layout.xxLargeScreen};
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: center;
      padding: 0rem 2.4rem;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0 2.4rem;
  }
    @media ${({ theme }) => theme.breakpoints.desktops_reverse} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4.4rem;
  }
`

const StyledContact = styled.div`
  // padding: 0 12rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 6rem;
  @media ${({ theme }) => theme.breakpoints.desktops_reverse} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4.4rem;
  }
  // @media ${({ theme }) => theme.breakpoints.mobile} {
  //   padding: 0 1.6rem;
  // }
`

const TextWrapper = styled.div`
  flex: 1;
`

const TitleWrapper = styled.div`
  margin-bottom: 2.4rem;
`

const BodyWrapper = styled.div`
  margin-bottom: 1.6rem;
  a {
    color: ${({ theme }) => theme.colors.link};
    &:hover {
      color: ${({ theme }) => theme.colors.link_hover};
    }
  }
`

const MailWrapper = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.link};
  &:hover {
    color: ${({ theme }) => theme.colors.link_hover};
  }
`
