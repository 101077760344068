const contactUrl = 'https://alhail-gateway.app.nebmist.com/api/v1/notification/email/contactus'

export const contactUs = (values) => {
    return new Promise((resolve, reject) => {
        fetch(`${ contactUrl }`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Basic YWxoYWlsOjFOZDN4eFdAa2smVA==`

            },
            body: JSON.stringify(values)
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                if (data.code === 6)
                    return reject(data)
                return resolve(data);
            })
    })
}
